import commonHelper from '@/app/utility/common.helper.utility';
import roleDetails from './roleDetails';

export default {
  name: 'role',
  components: {
    roleDetails
  },
  watch: {
    currentPage: function() {
      this.getRoles();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRoles();
    }
  },
  data() {
    return {
      unsubscribe: null,
      payload: null,
      loader: false,
      roleDetails: null,
      showRoleModal: false,
      showModuleForm: false,
      showMenuForm: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      setTimeFormAction: null,
      name: '',
      data: [],
      fields: [
        {
          key: 'role_id',
          class: 'd-none'
        },
        {
          key: 'role_name'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'start_date',
          label: 'Start Date'
        },
        {
          key: 'end_date',
          label: 'End Date'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showRoleModal = true;
        }
        if (this.showRoleModal) {
          this.setTimeFormAction = setTimeout(() => {
            this.eventBus.$emit('respFormActions', actionName);
          }, 0);
        }
        if (actionName === 'download' && !this.showRoleModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'resp/getRolesList',
            'role',
            () => (this.loader = false)
          );
        }
      }
    });
  },

  methods: {
    clearRole() {
      this.name = null;
      this.data = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    getRoles() {
      this.payload = {
        role_name: this.name,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store.dispatch('resp/getRolesList', this.payload).then(response => {
        if (response.status === 200) {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        }
      })
      .catch(() => {
        this.loader = false;
      });
    },
    updateRoleList() {
      this.getRoles();
    },
    resetModal() {},
    rowSelected(rowData) {
      this.showRoleModal = true;
      setTimeout(() => {
        this.eventBus.$emit('roleDetails', rowData);
      }, 0);
      this.roleDetails = rowData;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
