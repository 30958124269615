import DatePicker from 'vue2-datepicker';
import SearchModule from '../../responsibility/searchModule';
import commonHelper from '@/app/utility/common.helper.utility';
import SearchMenu from '../searchMenu';
import moment from 'moment';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'roleDetails',
  components: {
    SearchModule,
    SearchMenu,
    DatePicker,
    moment
  },
  data() {
    return {
      respTableIndex: 0,
      editMode: true,
      dtlEditMode: false,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      roleDetails: null,
      showRoleModal: false,
      showModuleForm: false,
      showMenuForm: false,
      data: [
        {
          module_id: null,
          module_name: null,
          active: true,
          menu_name: null,
          action_name: null,
          menu_title: null,
          menu_id: null,
          start_date: null,
          end_date: null,
          start_date_end_date: [],
          role_menu_mpng_id: 0,
          edit_allow: false,
          download_allow: false,
          upload_allow: false,
          add_allow: false,
          delete_allow: false,
          approval_allow: false,
          print_allow: false,
          menu_description: null
        }
      ],
      roleForm: {
        role_id: 0,
        role_name: null,
        role_desc: null,
        role_start_date: null,
        start_end_date: [],
        role_end_date: null,
        menu_details: null
      },
      fields: [
        {
          key: 'module_id',
          class: 'd-none'
        },
        {
          key: 'module_name',
          label: 'Module Name'
        },
        {
          key: 'active',
          class: 'd-none'
        },
        {
          key: 'menu_name',
          label: 'Menu Name'
        },
        {
          key: 'menu_description',
          label: 'Menu Description'
        },
        {
          key: 'action_name',
          class: 'd-none'
        },
        {
          key: 'menu_title',
          class: 'd-none'
        },
        {
          key: 'menu_id',
          class: 'd-none'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
        {
          key: 'role_menu_mpng_id',
          class: 'd-none'
        },
        {
          key: 'edit_allow'
        },
        {
          key: 'download_allow'
        },
        {
          key: 'upload_allow'
        },
        {
          key: 'add_allow'
        },
        {
          key: 'delete_allow'
        },
        {
          key: 'approval_allow'
        },
        {
          key: 'print_allow'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('roleDetails', obj => {
      this.editMode = false;
      this.roleForm.role_id = obj.role_id;
      this.roleForm.role_name = obj.role_name;
      this.roleForm.role_desc = obj.description;
      this.roleForm.role_start_date = obj.start_date;
      this.roleForm.role_end_date = obj.end_date;
      this.roleForm.start_end_date = [ new Date(obj.start_date), new Date(obj.end_date)]
      this.getRoleHeader(obj.role_id);
    });
    this.eventBus.$on('respFormActions', actionName => {
      if (actionName === 'edit' || actionName === 'update') {
        this.dtlEditMode = true;
        this.update = true;
      }
      if (actionName === 'add') {
        this.dtlEditMode = true;
        this.editMode = true;
        this.roleForm.role_id = 0;
      }
      if (actionName === 'save' && this.dtlEditMode) {
        this.addEditRole();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', { id: this.roleForm.role_id });
      }
    });
  },
  methods: {
    addEditRole() {
      const filterRoleDtl = this.data.filter( elem => {
        if(elem.menu_id !== null &&
        elem.start_date_end_date !== []
        ) {
          return elem;
        }
      })
      const details = filterRoleDtl.map(respDetails => {
        return {
          edit_allow: respDetails.edit_allow,
          add_allow: respDetails.add_allow,
          delete_allow: respDetails.delete_allow,
          download_allow: respDetails.download_allow,
          print_allow: respDetails.print_allow,
          approval_allow: respDetails.approval_allow,
          menu_end_date: commonHelper.formattedDate(
            respDetails.start_date_end_date[1]
          ),
          menu_id: respDetails.menu_id,
          menu_start_date: commonHelper.formattedDate(
            respDetails.start_date_end_date[0]
          ),
          upload_allow: respDetails.upload_allow
        };
      });
      const payload = {
        role_id: this.roleForm.role_id,
        role_name: this.roleForm.role_name,
        role_desc: this.roleForm.role_desc,
        role_start_date: commonHelper.formattedDate(
          this.roleForm.start_end_date[0]
        ),
        role_end_date: commonHelper.formattedDate(
          this.roleForm.start_end_date[1]
        ),
        menu_details: details ? details : null
      };
      this.loader = true;
      this.$store.dispatch('role/addEditRole', payload).then(response => {
        this.editMode = false;
        this.dtlEditMode = false;
        this.loader = false;
        this.$emit('updateRoleList');
        this.showAlert = true;
        if (response.status === 201) {
          this.roleForm.role_id = response.data.data.role_id;
          this.isSuccess = true;
          this.responseMsg = response.data.message;
        } else {
          this.isSuccess = false;
          this.responseMsg = response.response.data.message;
        }
      })
      .catch(() => {
        this.loader = false;
      });
    },
    getRoleHeader(roleId) {
      this.loader = true;
      this.$store.dispatch('role/getRoleHeaderList', roleId).then(response => {
        this.loader = false;
        if (response.status === 200) {
          const detail = response.data.data.menu_details;
          if (detail && detail.length > 0) {
            this.data = response.data.data.menu_details.map(data => {
              data.start_date_end_date = [
                new Date(data.start_date),
                new Date(data.end_date)
              ];
              return data;
            });
          }
        }
      })
      .catch(() => {
        this.loader = false;
      });
    },
    resetModal() {},
    addNewRow() {
      if (this.dtlEditMode) {
        this.data.push({
          module_id: null,
          module_name: null,
          active: true,
          menu_name: null,
          action_name: null,
          menu_title: null,
          menu_id: null,
          start_date: null,
          end_date: null,
          start_date_end_date: [],
          role_menu_mpng_id: 0,
          edit_allow: false,
          download_allow: false,
          upload_allow: false,
          add_allow: false,
          delete_allow: false,
          approval_allow: false,
          print_allow: false,
          menu_description: null
        });
      }
    },
    removeRow(index) {
      if (this.dtlEditMode) {
        this.data.splice(index, 1);
      }
    },
    openModuleForm(index) {
      if (this.dtlEditMode) {
        this.respTableIndex = index;
        if (this.data[this.respTableIndex].role_menu_mpng_id === 0) {
          this.showModuleForm = true;
        }
      }
    },
    selectedSearchModule(moduleData) {
      this.data[this.respTableIndex].module_name = moduleData.module_name;
      this.data[this.respTableIndex].module_id = moduleData.module_id;
      this.showModuleForm = false;
    },
    openMenuForm(index) {
      this.respTableIndex = index;
      if (this.dtlEditMode) {
        this.setTimeForMenu = setTimeout(() => {
          this.eventBus.$emit(
            'ModuleId',
            this.data[this.respTableIndex].module_id
          );
        }, 0);
        this.respTableIndex = index;
        if (
          this.data[this.respTableIndex].role_menu_mpng_id === 0 &&
          this.data[this.respTableIndex].module_name
        ) {
          this.showMenuForm = true;
        } else {
          alert(appStrings.DEPENDENTVSETMSG.MODULEMSG);
        }
      }
    },
    selectedSearchMenu(menuName) {
      this.data[this.respTableIndex].menu_name = menuName.menu_name;
      this.data[this.respTableIndex].module_id = menuName.module_id;
      this.data[this.respTableIndex].menu_id = menuName.menu_id;
      this.data[this.respTableIndex].menu_description =
        menuName.menu_description;
      this.showMenuForm = false;
    }
  },
  destroyed() {
    this.eventBus.$off('respFormActions');
    this.eventBus.$off('roleDetails');
  }
};
